import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';

import { routing } from './app.routing';

import { VisualComponent } from './components/legacy/visual/visual.component';
import { BandComponent } from './components/legacy/band/band.component';
import { AboutComponent } from './components/legacy/about/about.component';

import { AgensComponent } from './components/agens/agens.component';
import { CurrentComponent } from './components/current/current.component';
import { MusicComponent } from './components/music/music.component';
import { InfoComponent } from './components/info/info.component';
import { GlobeComponent } from './components/globe/globe.component';
import { DuoComponent } from './components/duo/duo.component';
import { KlassikComponent } from './components/klassik/klassik.component';
import { SoloComponent } from './components/solo/solo.component';

@NgModule({
  declarations: [
    // legacy components
    AboutComponent,
    VisualComponent,
    BandComponent,
    // active components
    AppComponent,
    AgensComponent,
    CurrentComponent,
    MusicComponent,
    InfoComponent,
    GlobeComponent,
    DuoComponent,
    KlassikComponent,
    SoloComponent
  ],
  imports: [
    routing,
    BrowserModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
