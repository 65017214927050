import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-music',
  templateUrl: './music.component.html',
  styleUrls: ['./music.component.css']
})
export class MusicComponent implements OnInit {
  public lines: string[] = [
    'agens - wie mag das sein',
    'I - exposition',
    'II - variation',
    'III - meditation',
    'IV - kontemplation',
    'V - substitution',
    'VI - sublimation',
  ];

  constructor() { }

  ngOnInit() {
  }

  open(url: string) {
    window.open(url);
  }

}
