import { Routes, RouterModule } from '@angular/router';

import { AgensComponent } from './components/agens/agens.component';
import { CurrentComponent } from './components/current/current.component';
import { MusicComponent } from './components/music/music.component';
import { InfoComponent } from './components/info/info.component';
import { GlobeComponent } from './components/globe/globe.component';
import { DuoComponent } from './components/duo/duo.component';
import { KlassikComponent } from './components/klassik/klassik.component';
import { SoloComponent } from './components/solo/solo.component';


const APP_ROUTES: Routes = [
  { path: '', component: GlobeComponent},
  { path: 'agens', component: AgensComponent},
  { path: 'current', component: CurrentComponent},
  { path: 'music', component: MusicComponent},
  { path: 'info', component: InfoComponent},
  { path: 'duo', component: DuoComponent},
  { path: 'solo', component: SoloComponent},
  { path: 'klassik', component: KlassikComponent}
];

export const routing = RouterModule.forRoot(APP_ROUTES);
