import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {
  state = 'default';
  private filltext = '';
  private filepath = '';

  constructor() { }

  ngOnInit() {
  }

  mover(input: string) {
    console.log('mouseover');
    this.filltext = 'test';
    this.filepath = './../assets/placeholder.png';
    if (input === 'bass') {
      this.filltext = 'bruno';
    } else if (input === 'gitarre') {
      this.filltext = 'peer';
    } else if (input === 'keyboard') {
      this.filltext = 'robert';
    } else if (input === 'schlagzeug') {
      this.filltext = 'marcus';
    } else if (input === 'vocals') {
      this.filltext = 'n n';
    } else {
      this.filltext = '';
      this.filepath = '';
    }
  }
  
  click() {
    this.state = 'banana';
  }

  public show(element: string) {
    document.getElementById(element).style.visibility = 'visible';
  }

  public hide(element: string) {
    document.getElementById(element).style.visibility = 'hidden';
  }
// send the message and get a callback with an error or details of the message that was sent
}
